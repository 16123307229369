import React, { useState, useEffect } from 'react';

import Hero from './HomePage/Hero';
import About from './HomePage/About';
import IndustryExper from './HomePage/IndustryExper';
import Portfolio from './HomePage/Portfolio';
import ContactForm from './HomePage/ContactForm';
import Services from './HomePage/Services';

const Home = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Show the modal 1 second after the page loads
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 1000);

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            {/* Main Sections */}
            <Hero />
            <About />
            <Services />
            <IndustryExper />
            <Portfolio />
            <ContactForm />

            {/* Popup Modal */}
            {showModal && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '20px', // Position 20px above the bottom
                        right: '20px', // Position 20px from the right
                        zIndex: 999,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light background for the popup
                        borderRadius: '8px',
                        padding: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        display: 'flex', // Flexbox for layout
                        alignItems: 'flex-end', // Align items to the bottom
                        gap: '10px', // Spacing between the images
                    }}
                >
                    <button
                        onClick={handleCloseModal}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: 'black',
                            fontSize: '16px',
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        &times;
                    </button>

                    <a
                        href="https://forms.gle/zQg8Ev4NfubTUtx27"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="images/popup.png" // Replace with actual image path
                            alt="Stay Secure Stay Resilient"
                            style={{
                                width: '300px', // Adjust the width of the popup image
                                height: 'auto',
                                borderRadius: '5px',
                            }}
                        />
                    </a>

                    <a
                        href="https://www.linkedin.com/feed/update/urn:li:activity:7289509690583961600"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="images/linkedin-logo.png" // Replace with actual image path
                            alt="Linkedin Path to Register"
                            style={{
                                width: '40px', // Adjust size as needed
                                height: '40px', // Adjust size as needed
                                borderRadius: '100%', // Make it circular
                            }}
                        />
                    </a>
                </div>

            )}
        </>
    );
};

export default Home;
