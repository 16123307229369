import React from "react";
import '../scss/our-company.scss';
import '../scss/our-services.scss';
import Breadcrumb from "../InnerPage/Breadcrumb";
import { serviceNav } from '../Data/BreadcrumbData';
import { BusinessStrategy } from '../Data/LeftColmnsData'
import { Link } from "react-router-dom";

const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
};


const Services = () => {
	return (

		<>

			<div class="choose-us-section style-4 bg-1 pt-100 pb-75">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">

							<div class="section-head text-center pb-75">
								<h5 className="text-center">+ Why Choose Us</h5>

								<h3 class=" text-center">We Offer A Wide  <br />Variety Of <span className="primary-orange ">IT Services *</span></h3>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-lg-4">
							<div class="content-single-items d-flex">
								<div class="content-inner text-right pb-30">
									<h2>Business Strategy & Solutions</h2>

									<p class=" text-right">A well-designed business and technology strategy is the foundation for digital.. </p>
									<div class="service-btn">
										<Link onClick={scrollToTop} to="/services/business-solutions">View More<i class="bi bi-arrow-right-short"></i></Link>
									</div>
								</div>
								<div class="content-icon">
									<img src={process.env.PUBLIC_URL + '/images/design-thinking.png'} alt="ValueSoft_services" />
								</div>
							</div>
							<div class="content-single-items d-flex">
								<div class="content-inner text-right">
									<h2>Staffing Augmentation</h2>
									<p class=" text-right">we offer a comprehensive range of staff augmentation services..</p>
									<div class="service-btn">
										<Link onClick={scrollToTop} to="/services/staffing-augmentation">View More<i class="bi bi-arrow-right-short"></i></Link>
									</div>
								</div>
								<div class="content-icon">
									<img src={process.env.PUBLIC_URL + '/images/select.png'} alt="ValueSoft_services" />
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-4">
							<div class="section-thumb">
								<img src={process.env.PUBLIC_URL + '/images/ch4.png'} alt="ValueSoft_services" />
							</div>
						</div>
						<div class="col-md-6 col-lg-4">
							<div class="content-single-items d-flex pb-30">
								<div class="content-icon">
									<img src={process.env.PUBLIC_URL + '/images/value.png'} alt="ValueSoft_services" />
								</div>
								<div class="content-inner text-left">
									<h2 class="content-title-inner">IT Technology Consulting </h2>
									<p>Whether your IT staffing requirements are permanent, temporary or..</p>
									<div class="service-btn">
										<Link onClick={scrollToTop} to="/services/technology-consulting">View More<i class="bi bi-arrow-right-short"></i></Link>
									</div>
								</div>
							</div>

							<div class="content-single-items d-flex">
								<div class="content-icon">
									<img src={process.env.PUBLIC_URL + '/images/supply-chain.png'} alt="ValueSoft_services" />
								</div>
								<div class="content-inner text-left">
									<h2>Procurement & Supply chain</h2>
									<p class=" text-left">Healthcare organizations and their stakeholders face unprecedented..</p>
									<div class="service-btn">
										<Link onClick={scrollToTop} to="/services/procurement-supplychain">View More<i class="bi bi-arrow-right-short"></i></Link>
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
			</div>
			<div class="counter-section style-5 pb-70 ">
				<div class="container">
					<div class="counter-container style-3 bg-3">
						<div class="row">
							<div class="col-md-6 col-lg-3">
								<div class="counter-single-item-inner d-flex">
									<div class="counter-icon">
										<img src={process.env.PUBLIC_URL + '/images/satisfaction.png'} alt="ValueSoft_services" />
									</div>
									<div class="counter-content">
										<div class="counter-text">
											<h1><span class="counter">2050 +</span></h1>

										</div>
										<div class="counter-title">
											<h4>Happy Customers</h4>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-lg-3">
								<div class="counter-single-item-inner d-flex">
									<div class="counter-icon">
										<img src={process.env.PUBLIC_URL + '/images/copy-writing.png'} alt="ValueSoft_services" />
									</div>
									<div class="counter-content">
										<div class="counter-text">
											<h1><span class="counter">1270 +</span></h1>

										</div>
										<div class="counter-title">
											<h4>Project Done</h4>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-lg-3">
								<div class="counter-single-item-inner d-flex">
									<div class="counter-icon">
										<img src={process.env.PUBLIC_URL + '/images/graph.png'} alt="ValueSoft_services" />
									</div>
									<div class="counter-content">
										<div class="counter-text">
											<h1><span class="counter">15 K</span></h1>

										</div>
										<div class="counter-title">
											<h4>Running Project</h4>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-lg-3">
								<div class="counter-single-item-inner d-flex">
									<div class="counter-icon">
										<img src={process.env.PUBLIC_URL + '/images/trophy.png'} alt="ValueSoft_services" />
									</div>
									<div class="counter-content">
										<div class="counter-text">
											<h1><span class="counter">200 +</span></h1>

										</div>
										<div class="counter-title">
											<h4>Award Wins</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="services-section style-3 pt-75 pb-50" style={{

				background: `linear-gradient(rgb(244 247 252 / 50%), rgb(244 247 252 / 50%)), url(${process.env.PUBLIC_URL + '/images/svbg3.png'})`
			}}>
				<div class="container">

					<div class="row">
						<div class="col-sm-12 col-md-6 col-lg-4 pl-0 pr-0">
							<div class="services-single-items">
								<div class="services-single-content">
									<div class="services-single-icon">
										<img src={process.env.PUBLIC_URL + '/images/computing.png'} alt="ValueSoft_services" />
									</div>
									<div class="services-content-inner">
										<h2>Microsoft 365</h2>
										<p>Unlock the full potential of Microsoft 365 with Valuesoft Info! We offer comprehensive...</p>
									</div>
								</div>
								<div class="service-bottom-link">
									<Link onClick={scrollToTop} to="/digital-tech/Microsoft-365"><i class="bi bi-arrow-right-short"></i></Link>
								</div>
							</div>
						</div>

						<div class="col-sm-12 col-md-6 col-lg-4 pl-0 pr-0">
							<div class="services-single-items">
								<div class="services-single-content">
									<div class="services-single-icon">
										<img src={process.env.PUBLIC_URL + '/images/virtual-reality.png'} alt="ValueSoft_services" />
									</div>
									<div class="services-content-inner">
										<h2>AR / VR</h2>
										<p>The Valuesoft Premium Partnership with Tiltlabs: Revolutionizing Product Realization..</p>
									</div>
								</div>
								<div class="service-bottom-link">
									<Link onClick={scrollToTop} to="/digital-tech/ar-vr"><i class="bi bi-arrow-right-short"></i></Link>
								</div>
							</div>
						</div>

						<div class="col-sm-12 col-md-6 col-lg-4 pl-0 pr-0">
							<div class="services-single-items">
								<div class="services-single-content">
									<div class="services-single-icon">

										<img src={process.env.PUBLIC_URL + '/images/outsourcing.png'} alt="ValueSoft_services" />

									</div>
									<div class="services-content-inner">
										<h2>Outsourcing</h2>
										<p>With delivery centers in both the U.S. and India, Valuesoft can offer a unique service..</p>
									</div>
								</div>
								<div class="service-bottom-link">
									<Link onClick={scrollToTop} to="/services/outsourcing"><i class="bi bi-arrow-right-short"></i></Link>
								</div>
							</div>
						</div>

						<div class="col-sm-12 col-md-6 col-lg-4 pl-0 pr-0">
							<div class="services-single-items">
								<div class="services-single-content">
									<div class="services-single-icon">
										<img src={process.env.PUBLIC_URL + '/images/chip.png'} alt="ValueSoft_services" />
									</div>
									<div class="services-content-inner">
										<h2>Business Intelligence</h2>
										<p>As Business Intelligence (BI) software developers continue to advance the technology’s..</p>
									</div>
								</div>
								<div class="service-bottom-link">
									<Link onClick={scrollToTop} to="/digital-tech/business-intelligence"><i class="bi bi-arrow-right-short"></i></Link>
								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-4 pl-0 pr-0">
							<div class="services-single-items">
								<div class="services-single-content">
									<div class="services-single-icon">
										<img src={process.env.PUBLIC_URL + '/images/assets.png'} alt="ValueSoft_services" />
									</div>
									<div class="services-content-inner">
										<h2>D A Management </h2>
										<p>CSR is one of the fundamental principles of Valuesoft. We participate in social projects..</p>
									</div>
								</div>
								<div class="service-bottom-link">

									<Link onClick={scrollToTop} to="/digital-tech/digital-asset-managment"><i class="bi bi-arrow-right-short"></i></Link>
								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-4 pl-0 pr-0">
							<div class="services-single-items">
								<div class="services-single-content">
									<div class="services-single-icon">
										<img src={process.env.PUBLIC_URL + '/images/corporate.png'} alt="ValueSoft_services" />
									</div>
									<div class="services-content-inner">
										<h2>Corporate Training</h2>
										<p>Organizations rely on Valuesoft Info IT Training and instructor-led training expertise to..</p>
									</div>
								</div>
								<div class="service-bottom-link">
									<Link onClick={scrollToTop} to="/services/corporate-training"><i class="bi bi-arrow-right-short"></i></Link>
								</div>
							</div>
						</div>

						<div class="section-head text-center pb-75">

							<h3 class=" text-center"> Our <span className="primary-orange ">Core Services*</span></h3>

						</div>

						<div class="row">
							{/*<!-- Database Administration -->*/}
							<div class="col-md-6 col-lg-3">
								<div class="content-single-items d-flex pb-30">
									<div class="content-inner text-right">
										<h2>Database Administration</h2>
										
										<div className="get-pack">
											<a href="/images/Database Administration.pdf" target="_blank" rel="noopener noreferrer">
											<p class="text-right">Streamline Your Data with Valuesoft Info’s Database Administration Services...</p>
												View More <i class="bi bi-arrow-right-short"></i>
											</a>
										</div>
									</div>
									<div class="content-icon">
										<img src={process.env.PUBLIC_URL + '/images/design-thinking.png'} alt="ValueSoft_services" />
									</div>
								</div>
							</div>

							{/*<!-- System Administration -->*/}
							<div class="col-md-6 col-lg-3">
								<div class="content-single-items d-flex pb-30">
									<div class="content-inner text-right">
										<h2>System Administration</h2>
										
										<div className="get-pack">
											<a href="/images/System Administration.pdf" target="_blank" rel="noopener noreferrer">
											<p class="text-right">Seamless IT Operations with Valuesoft Info’s System Administration...</p>
												View More <i class="bi bi-arrow-right-short"></i>
											</a>
										</div>
									</div>
									<div class="content-icon">
										<img src={process.env.PUBLIC_URL + '/images/select.png'} alt="ValueSoft_services" />
									</div>
								</div>
							</div>

							{/*<!-- Custom Software Development -->*/}
							<div class="col-md-6 col-lg-3">
								<div class="content-single-items d-flex pb-30">
									<div class="content-icon">
										<img src={process.env.PUBLIC_URL + '/images/value.png'} alt="ValueSoft_services" />
									</div>
									<div class="content-inner text-left">
										<h2 class="content-title-inner">Custom Software Development</h2>
										<div className="get-pack">
											<a href="/images/Custom Software Development.pdf" target="_blank" rel="noopener noreferrer">
											<p>Custom Software Development – Your Vision, Our Code...</p>
										
												View More <i class="bi bi-arrow-right-short"></i>
											</a>
										</div>
									</div>
								</div>
							</div>

							{/*<!-- Network Data & Analytics -->*/}
							<div class="col-md-6 col-lg-3">
								<div class="content-single-items d-flex pb-30">
									<div class="content-icon">
										<img src={process.env.PUBLIC_URL + '/images/supply-chain.png'} alt="ValueSoft_services" />
									</div>
									<div class="content-inner text-left">
										<h2 class="content-title-inner">Network Data & Analytics</h2>
										
										<div className="get-pack">
											<a href="/images/Network Data & Analytics.pdf" target="_blank" rel="noopener noreferrer">
											<p>Optimize Connectivity with Valuesoft Info’s Network Data &...</p>
												View More <i class="bi bi-arrow-right-short"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/*extension of experties*/}
						<div class="counter-section style-5 pb-70 ">
							<div class="container">
								<div class="counter-container style-3 bg-3">
									<div class="row">
										<div class="col-md-12 col-lg-12">
											<div class="counter-single-item-inner d-flex">
												<div class="counter-icon">
													<img src={process.env.PUBLIC_URL + '/images/E_O_E_.png'} alt="ValueSoft_services" />
												</div>
												<div class="counter-content">
													<div class="counter-text">
														<h2><span class="counter">Expanding Our Expertise Into:</span></h2>

													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6 col-lg-4">
								<div class="content-single-items d-flex">
									<div class="content-inner text-right pb-30">
										<h2>End-User Services</h2>

										<p class=" text-right">Seamless end-user computing with Microsoft 365 support, ensuring high productivity... </p>
										<div className="get-pack"><a href="/images/End-User Services.pdf" target="_blank" rel="noopener noreferrer">View More <i class="bi bi-arrow-right-short"></i> </a></div>

									</div>
									<div class="content-icon">
										<img src={process.env.PUBLIC_URL + '/images/End-User Services.png'} alt="ValueSoft_services" />
									</div>
								</div>
								<div class="content-single-items d-flex">
									<div class="content-inner text-right">
										<h2>Cyber Security</h2>
										<p class=" text-right">Robust data and system protection using Microsoft Azure Security Center and..</p>
										<div className="get-pack"><a href="/images/Cyber Security.pdf" target="_blank" rel="noopener noreferrer">View More <i class="bi bi-arrow-right-short"></i> </a></div>
									</div>
									<div class="content-icon">
										<img src={process.env.PUBLIC_URL + '/images/Cyber Security.png'} alt="ValueSoft_services" />
									</div>
								</div>
							</div>
							<div class="col-md-6 col-lg-4">
								<div class="section-thumb">
									<img src={process.env.PUBLIC_URL + '/images/ch_5.png'} alt="ValueSoft_services" />
								</div>
							</div>
							<div class="col-md-6 col-lg-4">
								<div class="content-single-items d-flex pb-30">
									<div class="content-icon">
										<img src={process.env.PUBLIC_URL + '/images/Service Management.png'} alt="ValueSoft_services" />
									</div>
									<div class="content-inner text-left">
										<h2 class="content-title-inner">Service Management </h2>
										<p>Enhance service delivery and customer satisfaction with ITIL-based service..</p>
										<div className="get-pack"><a href="/images/Service Management.pdf" target="_blank" rel="noopener noreferrer">View More <i class="bi bi-arrow-right-short"></i> </a></div>
									</div>
								</div>

								<div class="content-single-items d-flex pb-30">
									<div class="content-icon">
										<img src={process.env.PUBLIC_URL + '/images/Digitization.png'} alt="ValueSoft_services" />
									</div>


									<div class="content-inner text-left">
										<h2 class="content-title-inner">Digitization</h2>
										<p>Streamline operations and boost competitiveness with Microsoft Power Platform..</p>
										<div className="get-pack"><a href="/images/Digitization.pdf" target="_blank" rel="noopener noreferrer">View More <i class="bi bi-arrow-right-short"></i> </a></div>
									</div>
								</div>


							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
}

export default Services;