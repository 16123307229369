import React, { useEffect } from 'react';
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import { M365BD } from '../Data/BreadcrumbData';
import { M365AD } from '../Data/ArticleData';
import { M365Title } from '../Data/LeftColmnsData';
import "../scss/inner-page.scss";

// LeftColumn component for the left side content
const LeftColumn = ({ title }) => {
    return (
        <div className="call-do-action">
            <div className="top-link">
                <a href="#top">{title}<i className="bi bi-arrow-right-short"></i></a> {/* Updated href */}
            </div>
            <div className="single-pack d-flex">
                <div className="pack-icon">
                    <img src={process.env.PUBLIC_URL + '/images/download-file.png'} alt="Download file icon" /> {/* Added alt text */}
                </div>
                <div className="pack-content">
                    <div className="pack-title"><h5>M365 Case Study</h5></div>
                    <div className="get-pack">
                        <a href="/images/VSI_M365_CASE_STUDIES.pdf" download>Download PDF</a> {/* Ensured link is valid */}
                    </div>
                </div>
            </div>
            <div className="single-pack d-flex">
                <div className="pack-icon">
                    <img src={process.env.PUBLIC_URL + '/images/value.png'} alt="Sales queries icon" /> {/* Added alt text */}
                </div>
                <div className="pack-content">
                    <div className="pack-title"><h5>SALES QUERIES</h5></div>
                    <div className="get-pack">
                        <a href="mailto:sales@valuesoftinfo.com">Mail Us..!</a> {/* Ensured link is valid */}
                    </div>
                </div>
            </div>
            <div className="cda-content text-left">
                <h4>Contact Us</h4>
                <h3><a href="mailto:sales@valuesoftinfo.com"><i className="bi bi-envelope-open"></i> sales@valuesoftinfo.com </a></h3>
                <h3><a href="tel:080-43729615"><i className="bi bi-telephone-plus"></i> 080-43729615 </a></h3>
                <h3><i className="bi bi-geo-alt"></i> USA(Irving), UAE(Dubai), Malaysia(Kuala Lumpur), India(Bengaluru, Hyderabad, Pune)</h3>
                <ul className="social">
                    <li className="active">
                        <a href="https://www.facebook.com/valuesoftinfo/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-facebook"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/ValueSoft_info" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/value-soft-info-services-pvt-ltd-your-global-it-partner/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/valuesoftinfo" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

// M365Page component to render the full page
const M365Page = () => {
    useEffect(() => {
        const scriptId = 'd111552d-8a6b-4486-9381-222982f45556';
        const bannerHeight = 90;
        const bannerWidth = 728;
        const embedType = 'banner';

        const script = document.createElement('script');
        script.defer = true;
        /*script.src = `https://dmc.partner.microsoft.com/dccn/api/embedded-campaigns/js?id=${scriptId}&height=${bannerHeight}&width=${bannerWidth}&embedType=${embedType}`;*/
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []); // Effect hook to load the external script

    return (
        <section>
            <Breadcrumb {...M365BD} />
            <div className="single-service pt-75 pb-75">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <LeftColumn {...M365Title} />
                        </div>
                        <div className="col-lg-8">
                            <Article {...M365AD} />
                            <div className="ap-section-content">
                                <div className="overview-content">
                                    <h2 className="overview-title">Our Microsoft 365 Services:</h2>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="list-style-bullet">
                                                <li>Microsoft 365 Setup & Migration: Transition to Microsoft 365 with ease! Our experts handle everything from setup to migration, ensuring a smooth, hassle-free switch with zero downtime.</li>
                                                <li>Customization & Integration: Tailor Microsoft 365 to fit your business needs by integrating it with your existing tools and workflows. We customize everything from SharePoint to Teams, so your team gets the most out of the platform.</li>
                                                <li>Security & Compliance Solutions: Protect your data with Microsoft 365’s robust security features. We help you configure policies, ensure compliance with industry standards, and keep your sensitive information safe.</li>
                                                <li>Training & Support: Empower your team with expert training and ongoing support. We provide hands-on sessions to get your staff up to speed with Microsoft 365’s full range of features, maximizing productivity.</li>
                                                <li>Managed Microsoft 365 Services: Let us manage your Microsoft 365 environment for you. From regular updates to user management and troubleshooting, our team ensures everything runs smoothly while you focus on your business.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-6">
                                            <div className="overview-thumb mb-30">
                                                <img src="/images/innerpage/ai-2.png" alt="Microsoft 365 services overview" /> {/* Added alt text */}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            <div className="ap-content-inner d-flex">
                                                <div className="ap-content-icon">
                                                    <i className="bi bi-check-circle"></i>
                                                </div>
                                                <div className="ap-content-text ml-20"> Why Partner with Valuesoft Info?</div>
                                            </div>
                                            <div className="ap-content-inner d-flex mt-10">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <ul className="list-style-bullet">
                                                            <li>Deep Microsoft Expertise: Our certified professionals possess deep knowledge of the Microsoft ecosystem, ensuring every solution is tailored to your unique business needs.</li>
                                                            <li>End-to-End Solutions: From initial strategy and design to implementation, training, and ongoing support, we provide full lifecycle management of your Microsoft 365 environment.</li>
                                                            <li>Proven Results: With a track record of successful implementations across industries, Valuesoft Info has consistently helped businesses unlock the full potential of Microsoft 365, delivering measurable results.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="pt-15 pb-25">
                                        Supercharge your workplace with Valuesoft Info's Microsoft 365 Services—where productivity meets simplicity!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default M365Page;
